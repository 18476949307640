import { useState, useCallback } from 'react';
import { useApiState } from '../ApiStateContext';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;

export const useImages = (sessionId: string | null) => {
  const { apiState, updateApiState } = useApiState();
  const [pendingImages, setPendingImages] = useState<File[]>([]);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const handleImageChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (sessionId) {
      await uploadImages(files, sessionId);
    } else {
      console.log('Session ID is null, adding files to pending list:', files);
      setPendingImages(prevFiles => {
        const newPendingImages = [...prevFiles, ...files];
        console.log('Updated pending images:', newPendingImages);
        return newPendingImages;
      });
    }
  }, [sessionId]);

  const uploadImages = async (files: File[], currentSessionId: string) => {
    const uploadedUrls: string[] = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/upload-image/${currentSessionId}`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Failed to upload file: ${await response.text()}`);
        }

        const data = await response.json();
        uploadedUrls.push(data.imageUrl);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
    setUploadedImages(prevFiles => [...prevFiles, ...uploadedUrls]);
    updateApiState({ stimulusImages: [...apiState.stimulusImages, ...uploadedUrls] }, currentSessionId);
  };

  const uploadPendingImages = useCallback(async (currentSessionId: string) => {
    console.log('Uploading pending images:', pendingImages);
    if (currentSessionId && pendingImages.length > 0) {
      await uploadImages(pendingImages, currentSessionId);
      setPendingImages([]);
    }
  }, [pendingImages]);

  const handleImageRemove = (url: string, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setUploadedImages(prev => prev.filter(file => file !== url));
    updateApiState({ stimulusImages: apiState.stimulusImages.filter(image => image !== url) }, sessionId);
  };

  const handlePendingImageRemove = (file: File, e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setPendingImages(prev => prev.filter(f => f !== file));
  };

  const handleImageDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const event = {
      target: { files }
    } as unknown as React.ChangeEvent<HTMLInputElement>;
    handleImageChange(event);
  };

  return {
    pendingImages,
    setPendingImages,
    uploadedImages,
    setUploadedImages,
    handleImageChange,
    handleImageRemove,
    handlePendingImageRemove,
    handleImageDrop,
    uploadPendingImages,
  };
};