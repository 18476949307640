import React from 'react';
import styled from 'styled-components';
import { useApiState } from '../ApiStateContext';
import { CoreConfig } from './config/CoreConfig';
import { PromptsConfig } from './config/PromptsConfig';
import { SecondBrainsConfig } from './config/SecondBrainsConfig';
import { CurriculumConfig } from './config/CurriculumConfig';
import { TutorTopicConfig } from './config/TutorTopicConfig';
import { StudentInterestsConfig } from './config/StudentInterestsConfig';
import { EngagementMethodsConfig } from './config/EngagementMethodsConfig';
import { useSecondBrains } from '../hooks/useSecondBrains';
import { useImages } from '../hooks/useImages';

const Panel = styled.div`
  width: 450px;
  padding: 20px;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
`;

interface ConfigurationPanelProps {
  sessionId: string | null;
  imagesHook: ReturnType<typeof useImages>;
  secondBrainsHook: ReturnType<typeof useSecondBrains>;
}

const ConfigurationPanel: React.FC<ConfigurationPanelProps> = ({ sessionId, imagesHook, secondBrainsHook }) => {
  const { apiState, updateApiState } = useApiState();

  return (
    <Panel>
      <h2>AI Tutor Inputs</h2>
      <CoreConfig sessionId={sessionId} />
      <EngagementMethodsConfig sessionId={sessionId} />
      <PromptsConfig sessionId={sessionId} />
      <SecondBrainsConfig
        sessionId={sessionId}
        {...secondBrainsHook}
      />
      <CurriculumConfig sessionId={sessionId} />
      <TutorTopicConfig
        sessionId={sessionId}
        {...imagesHook}
      />
      <StudentInterestsConfig sessionId={sessionId} />
    </Panel>
  );
};

export default ConfigurationPanel;