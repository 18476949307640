import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { APIExposedState, LLMType, ClaudeModel, OpenAIModel, InceptAIModel } from './types/apiTypes';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;

if (!TUTOR_API_BACKEND_URL) {
  throw new Error('TUTOR_API_BACKEND_URL environment variable is not defined');
}

const defaultState: APIExposedState = {
  currentLLM: LLMType.InceptAI,
  currentOpenAIModel: OpenAIModel.GPT4oMini,
  currentClaudeModel: ClaudeModel.Sonnet,
  currentInceptAIModel: InceptAIModel.InceptK12,
  currentModel: InceptAIModel.InceptK12,
  curriculumSubjectId: '',
  curriculumCourseId: '',
  curriculumUnitId: '',
  curriculumTopicId: '',
  curriculumStandardId: '',
  curriculumTeacherTopic: '',
  userName: '',
  studentInterests: '',
  stimulusText: '',
  stimulusImages: [],
  avatar: 'Alpha',
  voiceOnly: false,
  favoriteSport: '',
  favoriteCelebrity: '',
  favoriteTVShow: '',
  avatarId: 51,
  engagementMethod: 'None',
  engagementMethodInputs: {},
  engagementMethodPrompt: '',
  guidelines: '',
  specialInstructions: '',
  secondBrains: [],
  speakingRate: 1.0,
  introPrompt: '',
  usStateAbbreviation: 'None',
  grade: '0',
  layout: 'vertical',
  colorProfile: 'default',
  microphoneMuted: false,
  verbosity: 0.5,
  tone: 0.5,
  customSystemPrompt: '',
  useCustomSystemPrompt: false,
  lastCustomSystemPrompt: '',
  customVideoWidth: '',
  customVideoHeight: '',
  customMessageHistoryWidth: '',
  customMessageHistoryHeight: '',
  customVideoObjectFit: '',
  isBasicChat: false,
  isVideoEnabled: true,
  isAudioEnabled: true,
  isTextEnabled: true,
};

const ApiStateContext = createContext<{
  apiState: APIExposedState;
  updateApiState: (updates: Partial<APIExposedState>, sessionId: string | null) => void;
} | undefined>(undefined);

export const ApiStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [apiState, setApiState] = useState<APIExposedState>(defaultState);

  const updateApiState = useCallback(async (updates: Partial<APIExposedState>, sessionId: string | null) => {
    setApiState(prevState => ({ ...prevState, ...updates }));

    if (sessionId) {
      try {
        console.log(`Updating session state for sessionId: ${sessionId}`);
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/update-state/${sessionId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updates),
        });

        if (!response.ok) {
          console.error('Failed to update session state:', await response.text());
        }
      } catch (error) {
        console.error('Error updating session state:', error);
      }
    }
  }, []);

  useEffect(() => {
    const fetchDefaultGuidelines = async () => {
      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/default-guidelines`);
        if (!response.ok) {
          throw new Error(`Failed to fetch default guidelines: ${response.statusText}`);
        }
        const data = await response.json();
        setApiState(prevState => ({ ...prevState, guidelines: data.guidelines }));
      } catch (error) {
        console.error('Error fetching default guidelines:', error);
      }
    };

    fetchDefaultGuidelines();
  }, []);

  useEffect(() => {
    const fetchIntroPrompt = async () => {
      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/default-intro-prompt`);
        if (!response.ok) {
          throw new Error(`Failed to fetch intro prompt: ${response.statusText}`);
        }
        const data = await response.json();
        console.log('Intro prompt:', data.introPrompt);
        setApiState(prevState => ({ ...prevState, introPrompt: data.introPrompt }));
      } catch (error) {
        console.error('Error fetching intro prompt:', error);
      }
    };

    fetchIntroPrompt();
  }, []);

  return (
    <ApiStateContext.Provider value={{ apiState, updateApiState }}>
      {children}
    </ApiStateContext.Provider>
  );
};

export const useApiState = () => {
  const context = useContext(ApiStateContext);
  if (context === undefined) {
    throw new Error('useApiState must be used within an ApiStateProvider');
  }
  return context;
};